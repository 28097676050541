import { Component, ElementRef } from '@angular/core';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  public constructor(
    protected elRef: ElementRef,
  ) {
    if (elRef.nativeElement) {
      elRef.nativeElement.setAttribute('app-version', environment.version);
      elRef.nativeElement.setAttribute('app-environment', environment.envName);
    }
  }

}
