import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import * as Raven from 'raven-js';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxHotjarModule } from 'ngx-hotjar';


// --- SENTRY [START] ---
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err.originalError || err);
  }
}

export function provideErrorHandler() {
  if (environment.hasOwnProperty('sentry')) {
    if (environment.sentry !== null) {
      Raven.config(environment.sentry, {environment: environment.envName}).install();
      return new RavenErrorHandler();
    }
  }
  return new ErrorHandler();
}
// --- SENTRY [END] ---

// --- HOT JAR [START] ---
const HOT_JAR_IMPORTS = [];
if (environment.hotJarTrackingCode) {
  HOT_JAR_IMPORTS.push(NgxHotjarModule.forRoot(environment.hotJarTrackingCode));
}
// --- HOT JAR [END] ---


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ...HOT_JAR_IMPORTS,
  ],
  providers: [
    {provide: ErrorHandler, useFactory: provideErrorHandler},
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
